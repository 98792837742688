import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import * as React from "react";
import { Link, useLocation } from "react-router-dom";
function NavSidebar({ isDrawerOpen, setIsDrawerOpen, headerNavItems }) {
  console.log(isDrawerOpen);
  let location = useLocation();
  return (
    <Drawer
      anchor="left"
      open={isDrawerOpen}
      onClose={() => {
        console.log("kk");
        setIsDrawerOpen(false);
      }}>
      <Box role="presentation" sx={{ width: 250 }}>
        <List>
          {headerNavItems.map(({ title, link }, index) => (
            <>
              <ListItem key={index} disablePadding>
                <Link to={link} className="hover:bg-slate-200  w-full">
                  <div className={`px-4 w-full py-2 text-center font-medium ${location.pathname === link ? "bg-slate-200 " : ""}`} key={index}>
                    {title}
                  </div>
                </Link>
              </ListItem>
              <Divider />
            </>
          ))}
        </List>
      </Box>
    </Drawer>
  );
}

export default NavSidebar;
