import React from "react";
import { Link } from "react-router-dom"; // Import Link from React Router
import Slider from "react-slick";
import image1 from "../../../assets/main/1.png";
import image2 from "../../../assets/main/2.png";
import image3 from "../../../assets/main/3.png";
import image4 from "../../../assets/main/4.png";
import image5 from "../../../assets/main/5.png";
import image6 from "../../../assets/main/6.png";
import image7 from "../../../assets/main/7.png";
import image8 from "../../../assets/main/8.png";
import image9 from "../../../assets/main/9.png";
import image10 from "../../../assets/main/10.png";
import image11 from "../../../assets/main/11.png";
import image12 from "../../../assets/main/12.png";
import image13 from "../../../assets/main/13.png";
import image14 from "../../../assets/main/14.png";
import image15 from "../../../assets/main/15.png";
import image16 from "../../../assets/main/16.png";
import image17 from "../../../assets/main/17.png";
import image18 from "../../../assets/main/18.png";
import image19 from "../../../assets/main/19.png";
import image20 from "../../../assets/main/20.png";

function CarouselImages() {
  const settings = {
    swipeToSlide: true,
    // dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,
    autoplaySpeed: 3000,
    centerPadding: "2px", // Adjusted padding
    cssEase: "linear",
  };

  return (
    <Slider {...settings} className="cursor-pointer bg-slate-100 overflow-x-clip">
      <div>
        <Link to="/bed"><img src={image18} alt="" srcset="" className="md:h-96 p-2" /></Link>
      </div>
      <div>
        <Link to="/bookShelfs"><img src={image1} alt="" srcset="" className="md:h-96 p-2" /></Link>
      </div>
      <div>
        <Link to="/bar-cabinets"><img src={image2} alt="" srcset="" className="md:h-96 p-2" /></Link>
      </div>
      <div>
        <Link to="/tvc"><img src={image3} alt="" srcset="" className="md:h-96 p-2" /></Link>
      </div>
      <div>
        <Link to="/chair"><img src={image4} alt="" srcset="" className="md:h-96 p-2" /></Link>
      </div>
      <div>
        <Link to="/almirah"><img src={image6} alt="" srcset="" className="md:h-96 p-2" /></Link>
      </div>
      <div>
        <Link to="/table"><img src={image8} alt="" srcset="" className="md:h-96 p-2" /></Link>
      </div>
      <div>
        <Link to="/coffee-tables"><img src={image9} alt="" srcset="" className="md:h-96 p-2" /></Link>
      </div>
      <div>
        <Link to="/sofa-cum-beds"><img src={image11} alt="" srcset="" className="md:h-96 p-2" /></Link>
      </div>
      <div>
        <Link to="/dinning-tables"><img src={image14} alt="" srcset="" className="md:h-96 p-2" /></Link>
      </div>
      <div>
        <Link to="/bar-cabinets"><img src={image16} alt="" srcset="" className="md:h-96 p-2" /></Link>
      </div>
      <div>
        <Link to="/bed"><img src={image17} alt="" srcset="" className="md:h-96 p-2" /></Link>
      </div>
      <div>
        <Link to="/sofa-cum-beds"><img src={image19} alt="" srcset="" className="md:h-96 p-2" /></Link>
      </div>
      <div>
        <Link to="/bookShelfs"><img src={image20} alt="" srcset="" className="md:h-96 p-2" /></Link>
      </div>
      <div>
        <Link to="/almirah"><img src={image7} alt="" srcset="" className="md:h-96 p-2" /></Link>
      </div>
      <div>
        <Link to="/tvc"><img src={image5} alt="" srcset="" className="md:h-96 p-2" /></Link>
      </div>
      <div>
        <Link to="/coffee-tables"><img src={image10} alt="" srcset="" className="md:h-96 p-2" /></Link>
      </div>
      <div>
        <Link to="/table"><img src={image12} alt="" srcset="" className="md:h-96 p-2" /></Link>
      </div>
      <div>
        <Link to="/chair"><img src={image13} alt="" srcset="" className="md:h-96 p-2" /></Link>
      </div>
      <div>
        <Link to="/dinning-tables"><img src={image15} alt="" srcset="" className="md:h-96 p-2" /></Link>
      </div>
    </Slider>
  );
}

export default CarouselImages;
