import React from 'react';
import { Typography } from "@mui/material";
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import AboutUsImage from "../../assets/wodden.jpg";
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

// Dynamically import images from a specific folder
function importAll(r) {
  let images = [];
  r.keys().map((item, index) => { images.push({ img: r(item), title: item.replace('./', '') }); });
  return images;
}

// Assuming your images are stored in 'src/assets/bar-cabinets'
const itemData = importAll(require.context('../../assets/bar-cabinets', false, /\.(png|jpe?g|svg)$/));

function BarCabinet() {
  return (
    <div>
      <div className="flex w-full justify-center items-center flex-col">
        <img src={AboutUsImage} alt="Bar Cabinet" className="filter brightness-50 object-cover h-72 w-full" />
        <div className="absolute w-full">
          <Typography variant="h3" component="h1" sx={{ color: "white", display: "flex", justifyContent: "center" }}>
            <p className="trirong-font"> Bar Cabinets</p>
          </Typography>
        </div>
      </div>
      <div className="mx-auto max-w-2xl  sm:px-1 sm:py-10 lg:max-w-7xl lg:px-1">
        <Box sx={{ width: "100%", height: "100%" }}>
          <ImageList variant="masonry" cols={3} gap={30}>
            {itemData.map((item, index) => (
              <Link to="/contact-us" key={index}> {/* Wrap ImageListItem inside Link */}
                <ImageListItem key={index} sx={{ border: '0px solid #e0e0e0', borderRadius: '8px' }}>
                  <img
                    srcSet={`${item.img}`}
                    alt={item.title}
                    className="h-full w-full object-cover object-center group-hover:opacity-75 transition-transform duration-300 transform hover:scale-105"
                    loading="lazy"
                    style={{ width: '100%', height: '100%', objectFit: 'cover', maxWidth: '400px', maxHeight: '400px' }}
                  />
                </ImageListItem>
              </Link>
            ))}
          </ImageList>
        </Box>
      </div>
    </div>
  );
}

export default BarCabinet;
