import { Typography } from "@mui/material";
import React from "react";
import AboutUsImage from "../../assets/wodden.jpg";
import ContactUsDetails from "./ContactUsDetails";
import ContactUsForm from "./ContactUsForm";
function ContactUs() {
  return (
    <div>
      <div className="flex w-full justify-center items-center flex-col">
        <img src={AboutUsImage} alt="bed" className="filter brightness-50 object-cover h-72 w-full  " />
        <div className="absolute w-full">
          <Typography variant="h3" component="h1" sx={{ color: "white", display: "flex", justifyContent: "center" }}>
            <p className="trirong-font"> Contact us</p>
          </Typography>
        </div>
      </div>
      <div className="flex justify-center p-5 flex-wrap">
        <div>
          <ContactUsDetails />
        </div>
        <div>
          <ContactUsForm />
        </div>
      </div>
      <div className="flex justify-center p-4">
        <div className="p-4 flex justify-center text-center flex-col">
          <h3 className="font-semibold">FIND US ON GOOGLE MAPS</h3>
          <p className="text-gray-700">You can find our office and manufacturing address by just clicking below </p>
          <div className="my-6 flex justify-center items-center ">
            <iframe
              title="location of shilp grah industry"
              src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3581.485262802508!2d72.98105997541336!3d26.148321177111047!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjbCsDA4JzU0LjAiTiA3MsKwNTknMDEuMSJF!5e0!3m2!1sen!2sin!4v1707297241304!5m2!1sen!2sin"
              width="1000px"
              height="500px"
              style={{ border: 0, width: "73vw", height: "50vh" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
