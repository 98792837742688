import React from "react";

function ContactUs() {
  return (
    <div>
      <div className="flex">
        <div className="text-zinc-300 font-semibold flex-1">Call : </div>
        <div className="text-zinc-300 flex-1">+91-7014318581</div>
      </div>
      <div className="flex">
        <div className="text-zinc-300 font-semibold flex-1">Mail : </div>
        <div className="text-zinc-300 flex-1">mihir .sharma@shilpgrah.com</div>
      </div>
      <div className="flex">
        <div className="text-zinc-300 font-semibold flex-1">Address : </div>
        <div className="text-zinc-300 flex-1">Plot No 12A, Khasra NO 93, Main Salawas Road,Jodhpur</div>
      </div>
    </div>
  );
}

export default ContactUs;
