import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
// import PersonIcon from "@mui/icons-material/Person";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/shilpgrah_logo.png";
import NavSidebar from "./NavSidebar";
const headerNavItems = [
  {
    title: "HOME",
    link: "/",
  },
  {
    title: "ABOUT US",
    link: "/about-us",
  },
  // {
  //   title: "GALLERY",
  //   link: "/gallery",
  // },
  {
    title: "FURNITURE",
    link: "/furniture",
  },
  {
    title: "GALLERY",
    link: "/gallery",
  },
  {
    title: "CONTACT US",
    link: "/contact-us",
  },
];
function TopHeader() {
  let location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  console.log(location.pathname);
  const handleMenuOpen = () => {
    console.log("handleMenuOpen");
    setIsDrawerOpen(true);
  };
  return (
    <header className="w-full flex items-center z-10 justify-between py-3 lg:px-10 md:px-5 sm:px-3 px-3">
      <nav className="flex items-center">
        <Link to="/">
          <div className="w-10 sm:w-20 h-auto flex flex-row items-center">
            <img src={logo} alt="" srcset="" />
          </div>
        </Link>
        <div className="hidden lg:flex  ml-6 gap-3 ">
          {headerNavItems.map(({ title, link }, index) => {
            return (
              <Link to={link} className="hover:bg-slate-200 rounded-lg ">
                <div className={`px-4 py-2 rounded-lg font-medium ${location.pathname === link ? "bg-slate-200 " : ""}`} key={index}>
                  {title}
                </div>
              </Link>
            );
          })}
        </div>
      </nav>
      <div className="flex gap-2">
        <button className="lg:hidden p-4 rounded-full bg-slate-100 cursor-pointer" onClick={handleMenuOpen}>
          <MenuIcon />
        </button>
        <div className="p-4 rounded-full bg-slate-100 cursor-pointer">
          <a href="tel:773-789-7996">
            <LocalPhoneOutlinedIcon className="!lg:text-xl !md:text-base !sm:text-xs" />
          </a>
        </div>
      </div>
      {isDrawerOpen && <NavSidebar isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} headerNavItems={headerNavItems} />}
    </header>
  );
}

export default TopHeader;
