
import "./index.css";
import Footer from "./layout/footer/Footer";
import TopHeader from "./layout/header/TopHeader";
import Routes from "./routes/index";
import Whatsapp from "./whatsapp/Whatsapp";
function App() {
  return (
    <div className="app">
      <div>
        <TopHeader />
      </div>
      <div>
        <Routes />
      </div>
      <footer>
        <Footer />
      </footer>
      <div className="w-full fixed z-50 bottom-4 right-4">
        <Whatsapp />
      </div>
    </div>
  );
}

export default App;
