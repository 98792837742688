import React from "react";
import AboutUs from "./AboutUs";
import ConnectWithUsOption from "./ConnectWithUsOption";
import ContactUs from "./ContactUs";
import ProductOptions from "./ProductOptions";

function Footer() {
  return (
    <div className="bg-slate-600 p-10">
      <div className="flex flex-wrap" style={{ justifyContent: "space-between" }}>
        <div  className="flex flex-col" style={{width:"400px"}}>
          <div className="font-bold text-slate-100 text-xl text-c py-5">Our products</div>
          <ProductOptions />
        </div>
        <div  className="flex flex-col" style={{width:"400px"}}>
          <div className="font-bold text-slate-100 text-xl py-5">Connect with us:</div>
          <ConnectWithUsOption />
        </div>
        <div  className="flex flex-col" style={{width:"400px"}}>
          <div className="font-bold text-slate-100 text-xl py-5">Contact Us</div>
          <ContactUs />
        </div>
      </div>
    </div>
  );
}

export default Footer;
