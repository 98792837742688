import React from "react";
import feed1 from "../../assets/feed1.jpg";
import bedroom1 from "../../assets/bedroom1.jpg";
import image4 from "../../assets/image4.jpg";
import CarouselImages from "./carousel/CarouselImages";

function Feed() {
  return (
    <>
      <CarouselImages />
      <section className="my-10">
        <div className="flex justify-center flex-wrap">
          <div
            className="flex items-center flex-wrap justify-center w-full"
            // style={{ flex: 0.5 }}
          >
            <div>
              {/* "relative left-60 " */}
              <div className="p-4">
                <p className="lg:text-6xl md:text-4xl sm:text-xl text-center font-bold mb-5">
                  Welcome to Shilpgrah
                </p>
                <div>
                  <p className="font-bold italic lg:text-1xl md:text-3xl sm:text-lg  mb-30">
                    Discover the timeless beauty of handcrafted solid wood
                    furniture at Shilpgrah...
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div >
            <img src={feed1} alt="" style={{ width: "100%" }} />
          </div>
        </div>
        <div className="flex flex-wrap">
          <div >
              <div className="mt-5">
                <p className="font-bold text-center lg:text-5xl md:text-3xl sm:text-lg font-bold mb-5">
                  Explore Our Collections
                </p>
                <div>
                  <div className="flex">
                    <p className="font italic text-gray-700 lg:text-lg md:text-base sm:text-sm  xl:block lg:block md:block sm:block mb-10">
                      <span className="font-bold italic">
                        Living Room Essentials:
                      </span>
                      Discover the artistry of comfort and style with our sofas,
                      coffee tables, and entertainment units. From sleek modern
                      lines to classic silhouettes, our living room collection
                      blends plush comfort with elegant designs
                    </p>
                  </div>
                  <div className="flex">
                    <p className="font italic text-gray-700 lg:text-lg md:text-base sm:text-sm  xl:block lg:block md:block sm:block mb-10">
                      <span className="font-bold italic">Dining in Style:</span>
                      Make every meal a celebration with our exquisite dining
                      tables and chairs. Crafted for durability and visual
                      appeal, our dining sets enhance the heart of your home.
                    </p>
                  </div>
                  <div className="flex">
                    <p className="font italic text-gray-700 lg:text-lg md:text-base sm:text-sm  xl:block lg:block md:block sm:block mb-10">
                      <span className="font-bold italic">
                        
                        Bedroom Sanctuaries
                      </span>
                      Transform your bedroom into a serene sanctuary with our
                      handcrafted bed frames, nightstands, and wardrobes. Choose
                      from a range of finishes and sizes to create a
                      personalized and restful retreat.
                    </p>
                  </div>
                </div>
              </div>
          </div>
          <div className="flex items-center flex-wrap" >
            <img
              src={image4}
              alt=""
              style={{ width: "100%" }}
              className="object-contain"
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default Feed;
