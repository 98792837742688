import React from "react";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import AboutUsImage from "../../assets/wodden.jpg";
import tableOne from "../../assets/table/1.png";
import bedOne from "../../assets/bed/1.png";
import AlmirahOne from "../../assets/Almirah/5.png";
import BedsidesOne from "../../assets/bedsides/1.png";
import BookshelfOne from "../../assets/bookshelves/1.png";
import SideboardsOne from "../../assets/sideboards/1.png";
import CoffetablesOne from "../../assets/coffee-table/9.png";
import tvcOne from "../../assets/tvc/1.png";
import SofacumbedOne from "../../assets/sofa-cum-bed/1.png";
import chairOne from "../../assets/chair/1.png";
import DinningtableOne from "../../assets/dinning-table/1.png";
import barOne from "../../assets/bar-cabinets/1.png";

const itemData = [
  {
    title: "Table",
    link: "/table",
    items: [
      {
        img: tableOne,
        title: "Storage bed",
      },
      // Add more items for the "Table" category if needed
    ],
  },
  {
    title: "sofa cum beds",
    link: "/sofa-cum-beds",
    items: [
      {
        img: SofacumbedOne,
        title: "Storage bed",
      },
      // Add more items for the "Chair" category if needed
    ],
  },
  {
    title: "Dinning Tables",
    link: "/dinning-tables",
    items: [
      {
        img: DinningtableOne,
        title: "Storage bed",
      },
      // Add more items for the "Chair" category if needed
    ],
  },
  {
    title: "Coffe tables",
    link: "/coffee-tables",
    items: [
      {
        img: CoffetablesOne,
        title: "Storage bed",
      },
      // Add more items for the "Chair" category if needed
    ],
  },
  {
    title: "Bed",
    link: "/bed",
    items: [
      {
        img: bedOne,
        title: "Storage bed",
      },
      // Add more items for the "Bed" category if needed
    ],
  },
  {
    title: "Bedsides",
    link: "/bedsides",
    items: [
      {
        img: BedsidesOne,
        title: "Storage bed",
      },
      // Add more items for the "Chair" category if needed
    ],
  },
  {
    title: "Sideboards",
    link: "/sideboards",
    items: [
      {
        img: SideboardsOne,
        title: "Storage bed",
      },
      // Add more items for the "Chair" category if needed
    ],
  },
  {
    title: "BookShelf",
    link: "/bookShelfs",
    items: [
      {
        img: BookshelfOne,
        title: "Storage bed",
      },
      // Add more items for the "Chair" category if needed
    ],
  },
  {
    title: "Chair",
    link: "/chair",
    items: [
      {
        img: chairOne,
        title: "Storage bed",
      },
      // Add more items for the "Chair" category if needed
    ],
  },
  {
    title: "Almirah",
    link: "/almirah",
    items: [
      {
        img: AlmirahOne,
        title: "Storage bed",
      },
      // Add more items for the "Chair" category if needed
    ],
  },
  {
    title: "Bar Cabinets",
    link: "/bar-cabinets",
    items: [
      {
        img: barOne,
        title: "Storage bed",
      },
      // Add more items for the "Chair" category if needed
    ],
  },
  {
    title: "TVC",
    link: "/tvc",
    items: [
      {
        img: tvcOne,
        title: "Storage bed",
      },
      // Add more items for the "Chair" category if needed
    ],
  },
];

function Furniture() {
  return (
    <div>
      <div className="flex w-full justify-center items-center flex-col">
        <img src={AboutUsImage} alt="bed" className="filter brightness-50 object-cover h-72 w-full" />
        <div className="absolute w-full">
          <Typography variant="h3" component="h1" sx={{ color: "white", display: "flex", justifyContent: "center" }}>
            <p className="trirong-font"> Furniture</p>
          </Typography>
        </div>
      </div>
      <div>
        <div className="bg-white">
          <div className="mx-auto max-w-2xl px-1 py-16 sm:px-1 sm:py-24 lg:max-w-7xl lg:px-1">
            <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 xl:gap-x-8">
              {itemData.map(({ items, title, link }) => (
                <div key={title} className="flex flex-col items-center">
                  <Link to={link}>
                    <h2 className="text-xl font-bold tracking-tight text-gray-900 p-2">{title}</h2>
                  </Link>
                  {items.map(({ img, title }) => (
                    <div key={title} className="h-60 aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                      <Link to={link}>
                        <img src={img} alt={title} className="h-full w-full object-cover object-center group-hover:opacity-75 transition-transform duration-300 transform hover:scale-105" />
                      </Link>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Furniture;
