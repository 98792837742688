import React from "react";
import { Link } from "react-router-dom";

function ProductOptions() {
  return (
    <div className="grid grid-cols-2 grid-rows-1 gap-4">
      <div className="flex flex-col">
        <Link to="/chair" className="text-zinc-300 font-semibold cursor-pointer">Chair</Link>
        <Link to="/table" className="text-zinc-300 font-semibold cursor-pointer">Table</Link>
        <Link to="/bookshelfs" className="text-zinc-300 font-semibold cursor-pointer">Bookshelf</Link>
        <Link to="/bar-cabinets" className="text-zinc-300 font-semibold cursor-pointer">Bar cabinets</Link>
      </div>
      <div className="flex flex-col">
        <Link to="/coffee-tables" className="text-zinc-300 font-semibold cursor-pointer">Coffee table</Link>
        <Link to="/bed" className="text-zinc-300 font-semibold cursor-pointer">Bed</Link>
        <Link to="/dinning-tables" className="text-zinc-300 font-semibold cursor-pointer">Dinning Tables</Link>
        <Link to="/sofa-cum-beds" className="text-zinc-300 font-semibold cursor-pointer">soda cum beds</Link>
        {/* Add an empty link or placeholder if needed */}
      </div>
      <div className="flex flex-col">
        <Link to="/bedsides" className="text-zinc-300 font-semibold cursor-pointer">Bedsides</Link>
        <Link to="/sideboards" className="text-zinc-300 font-semibold cursor-pointer">Sideboards</Link>
        <Link to="/almirah" className="text-zinc-300 font-semibold cursor-pointer">Almirah</Link>
        <Link to="/tvc" className="text-zinc-300 font-semibold cursor-pointer">TVC</Link>
      </div>
    </div>
  );
}

export default ProductOptions;
