import { Typography } from "@mui/material";
import React from "react";
import AboutUsImage from "../../assets/wodden.jpg";
import bedroom1 from "../../assets/bedroom1.jpg";
// import "./aboutUs.css";
function AboutUs() {
  return (
    <div className="about-us">
      <div className="flex w-full justify-center items-center flex-col">
        <img src={AboutUsImage} alt="bed" className="filter brightness-50 object-cover h-72 w-full" />
        <div className="absolute w-full">
          <Typography variant="h3" component="h1" sx={{ color: "white", display: "flex", justifyContent: "center" }}>
            <p className="trirong-font"> About Us </p>
          </Typography>
        </div>
      </div>
      <div className="p-6">
      <div className="flex gap-6 my-6 items-center flex-wrap">
          <div className="lg:flex-1">
            <p className="font-bold italic text-gray-700 lg:text-lg md:text-base sm:text-sm xl:block lg:block md:block sm:block mb-10">
              Established in Jodhpur, the heart of handicrafts in India, Shilpgrah is renowned for its artistic and high-quality solid wood furniture.
              For years, we have been crafting furniture that reflects the cultural heritage of India while meeting contemporary designs and functionality.
            </p>
            <p className="font-bold italic text-gray-700 lg:text-lg md:text-base sm:text-sm xl:block lg:block md:block sm:block mb-10">
              At Shilpgrah, solid wood is our passion. We ethically source the best woods like Sheesham, Mango, Teak, and Rosewood to create sturdy, durable, and elegant furniture.
              From beds, sofas, dining sets, wardrobes to coffee tables and more - our solid wood furniture adds sophistication to any living space.
              Customization is our forte. We understand that furniture must match your personal style and space functionality.
            </p>
            <p className="font-bold italic text-gray-700 lg:text-lg md:text-base sm:text-sm xl:block lg:block md:block sm:block mb-10">
              We also undertake bespoke projects for special designs.
            </p>
            <p className="font-bold italic text-gray-700 lg:text-lg md:text-base sm:text-sm xl:block lg:block md:block sm:block mb-10">
              Our commitment to quality, craftsmanship, and timely delivery has earned us a loyal clientele across India and overseas.
              We are proud to ship our solid wood masterpieces globally while meeting international quality benchmarks.
            </p>
          </div>
          <div className="lg:flex-1">
            <img src={bedroom1} alt="furniture1" className="w-auto" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
