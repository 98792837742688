import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PinDropIcon from "@mui/icons-material/PinDrop";
const contactUsItems = [
  {
    title: "Telephone number",
    icon: <LocalPhoneIcon sx={{ color: "rgb(77 124 15)" }} />,
    detail: `+91-7014318581`,
  },
  {
    title: "Email address",
    icon: <EmailIcon sx={{ color: "rgb(77 124 15)" }} />,
    detail: "mihir.sharma@shilpgrah.com",
  },
  {
    title: "Location",
    icon: <PinDropIcon sx={{ color: "rgb(77 124 15)" }} />,
    detail: "Plot No 12A, Khasra NO 93, Main Salawas Road,Jodhpur",
  },
];
function ContactUsDetails() {
  return (
    <div className="container">
      {contactUsItems.map((item, index) => {
        return (
          <div className="flex bg-slate-100 my-2 py-3 px-4 rounded-lg " key={index}>
            <div className="p-4 rounded-full" style={{ border: "1px solid rgb(77 124 15)" }}>
              {item.icon}
            </div>
            <div className="ml-2 flex flex-col justify-center">
              <div className="font-semibold text-gray-700">{item.title}</div>
              <div className="text-gray-700">{item.detail}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ContactUsDetails;
