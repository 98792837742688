import React from "react";

function AboutUs() {
  return (
    <div className="text-zinc-300 font-semibold">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam mollitia sint quis a voluptatibus ut laudantium dignissimos labore eveniet aperiam inventore repudiandae illo earum, aliquid odio
      placeat amet sunt. Perspiciatis temporibus fugiat eius neque ducimus delectus recusandae minima labore quas sunt magni velit amet, quidem fugit cupiditate vero ab aperiam inventore excepturi
      sapiente.
    </div>
  );
}

export default AboutUs;
