import React from 'react';
import { Typography } from "@mui/material";
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import AboutUsImage from "../../assets/wodden.jpg";

// Dynamically import images from a specific folder
function importAll(r) {
  let images = [];
  r.keys().map((item, index) => { images.push({ img: r(item), title: item.replace('./', '') }); });
  return images;
}

// Assuming your images are stored in 'src/assets/bed'
const itemData = importAll(require.context('../../assets/gallery', false, /\.(png|jpe?g|svg)$/));

function BarCabinet() {
  return (
    <div>
      <div className="flex w-full justify-center items-center flex-col">
        <img src={AboutUsImage} alt="bed" className="filter brightness-50 object-cover h-72 w-full" />
        <div className="absolute w-full">
          <Typography variant="h3" component="h1" sx={{ color: "white", display: "flex", justifyContent: "center" }}>
            <p className="trirong-font"> Gallery</p>
          </Typography>
        </div>
      </div>
      <div className="my-4 mx-2">
        <Box sx={{ width: "100%", height: "100%" }}>
          <ImageList variant="masonry" cols={3} gap={8}>
            {itemData.map((item, index) => (
              <ImageListItem key={index} sx={{ border: '1px solid #e0e0e0', borderRadius: '8px' }}>
                <img
                  srcSet={`${item.img}`}
                  alt={item.title}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      </div>
    </div>
  );
}

export default BarCabinet;
