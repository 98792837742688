import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import Textarea from "@mui/joy/Textarea";
import React from "react";
function ContactUsForm() {
  return (
    <div className="container py-5 bg-slate-100 rounded-lg">
      <p className="font-semibold text-gray-700 py-2">Send Message</p>
      <div>
        <div className="m-auto grid grid-cols-2 gap-4">
          <Input placeholder="Your name" variant="outlined" />
          <Input placeholder="Email address" variant="outlined" />
          <Input placeholder="Phone number" variant="outlined" />
          <Input placeholder="Subject" variant="outlined" />
        </div>
        <div className="my-4">
          <Textarea disabled={false} minRows={2} size="lg" variant="outlined" color="primary" placeholder="Message" />
        </div>
        <Button endDecorator={<KeyboardArrowRight />} sx={{ backgroundColor: "orange" }}>
          Send Message
        </Button>
      </div>
    </div>
  );
}

export default ContactUsForm;
