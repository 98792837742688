import Box from "@mui/material/Box";
import React from "react";
import WhatsApp from "../assets/svg/WhatsApp";
function Whatsapp() {
  return (
    <div className="flex justify-end">
      <Box sx={{ "& > :not(style)": { m: 1 }, cursor: "pointer" }}>
        <a href="https://wa.me/7014318581" target="_blank" rel="noreferrer">
          <WhatsApp />
        </a>
      </Box>
    </div>
  );
}

export default Whatsapp;
