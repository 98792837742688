import AboutUs from "../component/about-us/AboutUs";
import ContactUs from "../component/contact-us/ContactUs";
import Feed from "../component/feed/Feed";
import Bed from "../component/furniture/Bed";
import Chair from "../component/furniture/Chair";
import Sideboards from "../component/furniture/Sideboards";
import Furniture from "../component/furniture/Furniture";
import Table from "../component/furniture/Table";
import Gallery from "../component/gallery/Gallery";
import Tvc from "../component/furniture/tvc";
import BarCabinet from "../component/furniture/Bar-cabinets";
import Bedsides from "../component/furniture/Bedsides";
import Bookshelfs from "../component/furniture/Bookshelf";
import Coffetables from "../component/furniture/Coffe-tables";
import Dinningtable from "../component/furniture/dinning-table";
import Sofacumbed from "../component/furniture/Sofa_cum_bed";
import Almirah from "../component/furniture/Almirah";

const MainRoutes = [
  {
    path: "/",
    element: <Feed />,
  },
  {
    path: "/about-us",
    element: <AboutUs />,
  },
  {
    path: "/gallery",
    element: <Gallery />,
  },
  {
    path: "/furniture",
    element: <Furniture />,
  },
  {
    path: "/contact-us",
    element: <ContactUs />,
  },
  {
    path: "/bed",
    element: <Bed />,
  },
  {
    path: "/table",
    element:<Table/> ,
  },
  {
    path: "/chair",
    element: <Chair />,
  },
  {
    path: "/almirah",
    element: <Almirah />,
  },
  {
    path: "sofa-cum-beds",
    element: <Sofacumbed />,
  },
  {
    path: "/sideboards",
    element: <Sideboards />,
  },
  {
    path: "/tvc",
    element: <Tvc />,
  },
  {
    path: "/bar-cabinets",
    element: <BarCabinet/>,
  },
  {
    path: "/bedsides",
    element: <Bedsides />,
  },
  {
    path: "/bookshelfs",
    element: <Bookshelfs />,
  },
  {
    path: "/coffee-tables",
    element: <Coffetables />,
  },
  {
    path: "/dinning-tables",
    element: <Dinningtable />,
  },
];

export default MainRoutes;
