import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

function ConnectWithUsOption() {
  const instagramLink = "https://www.instagram.com/shilp.grah/";
  const linkedinLink = "https://www.linkedin.com/in/shilpgrah-handicrafts-ab62272b7/";
  const pinterestLink = "https://www.pinterest.com/mihirsharma0045/";

  const openInstagramProfile = () => {
    window.open(instagramLink, "_blank"); // Open the Instagram link in a new tab
  };
  const openPinterestProfile = () => {
    window.open(pinterestLink, "_blank"); // Open the LinkedIn link in a new tab
  };
  const openLinkedInProfile = () => {
    window.open(linkedinLink, "_blank"); // Open the LinkedIn link in a new tab
  };

  return (
    <div className="flex gap-4">
      <InstagramIcon
        className="cursor-pointer text-white !h-8 !w-8"
        onClick={openInstagramProfile}
      />
      <PinterestIcon 
        className="cursor-pointer text-white !h-8 !w-8"
        onClick={openPinterestProfile}/>
      <LinkedInIcon
        className="cursor-pointer text-white !h-8 !w-8"
        onClick={openLinkedInProfile}
      />
    </div>
  );
}

export default ConnectWithUsOption;
